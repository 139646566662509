<template>
  <div class="AutoLogin">
    <SimpleHeader />

    <div class="ContentWrap">
      <!-- Loading -->
      <div
        v-if="loadingStatus === 'loading'"
        class="LoadingWrap">
        <span>{{ mixWB('YOU_ARE_BEING_LOGGED_IN') }}</span>
        <div class="Loading">
          <Circular />
        </div>
      </div>

      <!-- Error -->
      <div
        v-if="loadingStatus === 'error'"
        class="ErrorWrap">
        <span class="Title">{{ mixWB('SOMETHING_WENT_WRONG') }}</span>
        <span v-if="errorCode === 'auth/invalid-action-code'">
          {{ mixWB('LINK_EXPIRED_TEXT') }}
        </span>
        <span v-else-if="errorCode === 'auth/invalid-email'">
          {{ mixWB('THE_USED_EMAIL_IS_WRONG_TEXT', [emailUsed]) }}
        </span>
        <span v-else>
          {{
            mixWB('PLEASE_TRY_AGAIN_OR_CONTACT_US_TEXT')
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular'
import SimpleHeader from '@/components/SimpleHeader'
import { auth } from '@/firebase/init'
import { mapGetters } from 'vuex'
import { reportError } from '@/globals/javascript/custom-error-handing'

export default {
  name: 'AutoLogin',
  data() {
    return {
      loadingStatus: 'loading',
      errorCode: '',
      emailUsed: '',
    }
  },
  computed: {
    ...mapGetters([
      'currentUserLoaded',
      'currentUserData',
    ]),
  },
  methods: {
    completeSignIn() {
      if (auth.isSignInWithEmailLink(window.location.href)) {
        const isAdminLogin = this.$route.query.isAdmin
        let email = window.localStorage.getItem('adminEmailForSignIn')
        if (!email) {
          email = this.$route.query.email
        }
        this.emailUsed = email

        auth.signInWithEmailLink(email, window.location.href)
          .then(() => {
            if (isAdminLogin) {
              this.signInAsAdmin(email)
              return
            }

            this.signInAsNormalUser(email)
          })
          .catch((err) => {
            this.loadingStatus = 'error'
            this.errorCode = err.code

            reportError({ error: err, place: 'completeSignIn' })
          })
      }
      else {
        this.loadingStatus = 'error'
      }
    },
    signInAsAdmin(email) {
      // Remove email from local storage
      window.localStorage.removeItem('adminEmailForSignIn')

      this.$store.dispatch('getCurrentUser', email)
      this.moveUserToStartPage(true)
    },
    signInAsNormalUser(email) {
      // Remove email from local storage
      window.localStorage.removeItem('emailForSignIn')

      this.$store.dispatch('getCurrentUser', email)
      this.$store.dispatch('setStatsOnLogin')
      this.moveUserToStartPage()
    },
    moveUserToStartPage(isAdmin = false) {
      const waitForCurrentUser = setInterval(() => {
        if (!this.currentUserLoaded) {
          return
        }

        clearInterval(waitForCurrentUser)

        if (isAdmin) {
          this.$router.push({ name: 'AdminDashboard' })
          return
        }
        this.$router.push({ name: 'Start' })
      }, 100)
    },
  },
  components: {
    Circular,
    SimpleHeader,
  },
  mounted() {
    this.completeSignIn()
  },
}
</script>

<style lang="stylus" scoped>
  .AutoLogin
    display block
    padding-bottom 100px
    span
      text-align center

  .ContentWrap
    width 570px
    padding 50px 50px 70px
    background-color rgba($color_grey_lightest, 0.8)
    margin 0 auto

  .LoadingWrap
    .Loading
      position relative
      box(100px)
      margin 30px auto

  .ErrorWrap
    span
      margin-bottom 20px
    .Title
      font-weight bold
</style>
